import React, { useEffect, useState } from 'react';
import Style from '../permitRequest.module.css';
import { usePermitRequestContext } from '../../../../contexts/PermitRequestContext';
import InputGroup from '../../../../components/form/InputGroup';
import RadioBtn from '../../../../components/form/radio/RadioBtn';
import RadioBtnsContainer from '../../../../components/form/radio/RadioBtnsContainer';
import { useProjectConfigContext } from '../../../../contexts/ProjectConfigContext';
import SectionTitle from '../SectionTitle';
import FormSection from './FormSection';

function AdditionalAddressFormSection() {

    const { projectConfigState } = useProjectConfigContext();
    const { handleChange, errors, values, touched, handleBlur, setFieldValue } = usePermitRequestContext();
    const [radioBtnState, setChecked] = useState([0, 1]);

    const hasAdditionalAddress = values.hasAdditionalAddress;
    const AdditionalAddrDetails = values.additionalContactDetails;
    const AdditionalAddrDetailsErrors = errors.additionalContactDetails ? errors.additionalContactDetails : {};
    const AdditionalAddrDetailsTouched = touched.additionalContactDetails ? touched.additionalContactDetails : {};

    useEffect(() => {

        setChecked([hasAdditionalAddress == 1 ? true : false, hasAdditionalAddress == 0 ? true : false]);
    }, [hasAdditionalAddress]);

    if (!projectConfigState.hasAdditionalAddress) {
        return '';
    }

    return (
        <FormSection>
            <SectionTitle title='פרטי כתובת למשלוח' />
            <div className='row'>
                <div className='col-sm'>
                    <RadioBtnsContainer label={'האם קיימת כתובת שונה למשלוח תו חנייה?'}>
                        <RadioBtn
                            isChecked={radioBtnState[0]}
                            label='כן'
                            value={1}
                            onChange={() => { }}
                            onClick={handleClick}
                            id="additionalAddressYes"
                            name='hasAdditionalAddress'
                        />
                        <RadioBtn
                            isChecked={radioBtnState[1]}
                            label='לא'
                            value={0}
                            onChange={() => { }}
                            onClick={handleClick}
                            id="additionalAddressNo"
                            name='hasAdditionalAddress'
                        />
                    </RadioBtnsContainer>
                </div>
                {hasAdditionalAddress === 1 ? (
                    <>
                        <div className='row'>
                            <div className='col-md-6 col-lg-3'>
                                <InputGroup
                                    name={'additionalContactDetails.mailCity'}
                                    label={'עיר למשלוח'}
                                    value={AdditionalAddrDetails.mailCity}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    isRequired={true}
                                    errorMsg={(AdditionalAddrDetailsErrors.mailCity && AdditionalAddrDetailsTouched.mailCity) ? AdditionalAddrDetailsErrors.mailCity : undefined} />
                            </div>
                            <div className='col-md-3 col-lg-3'>
                                <InputGroup
                                    name={'additionalContactDetails.mailStreet'}
                                    label={'רחוב'}
                                    value={AdditionalAddrDetails.mailStreet}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    isRequired={true}
                                    errorMsg={(AdditionalAddrDetailsErrors.mailStreet && AdditionalAddrDetailsTouched.mailStreet) ? AdditionalAddrDetailsErrors.mailStreet : undefined} />
                            </div>
                            <div className='col-md-6 col-lg-2'>
                                <InputGroup
                                    name={'additionalContactDetails.mailHouse'}
                                    label={'בית'}
                                    value={AdditionalAddrDetails.mailHouse}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    isRequired={true}
                                    errorMsg={(AdditionalAddrDetailsErrors.mailHouse && AdditionalAddrDetailsTouched.mailHouse) ? AdditionalAddrDetailsErrors.mailHouse : undefined} />
                            </div>

                        </div>
                        <div className='row'>
                            <div className='col-md-6 col-lg-2'>
                                <InputGroup
                                    name={'additionalContactDetails.mailAppartment'}
                                    label={'דירה'}
                                    value={AdditionalAddrDetails.mailAppartment}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    isRequired={true}
                                    errorMsg={(AdditionalAddrDetailsErrors.mailAppartment && AdditionalAddrDetailsTouched.mailAppartment) ? AdditionalAddrDetailsErrors.mailAppartment : undefined} />
                            </div>
                            <div className='col-md-6 col-lg-2'>
                                <InputGroup
                                    name={'additionalContactDetails.mailMikod'}
                                    label={'מיקוד'}
                                    value={AdditionalAddrDetails.mailMikod}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    isRequired={true}
                                    errorMsg={(AdditionalAddrDetailsErrors.mailMikod && AdditionalAddrDetailsTouched.mailMikod) ? AdditionalAddrDetailsErrors.mailMikod : undefined} />
                            </div>
                        </div>
                    </>) : ''
                }
            </div>
        </FormSection>
    );

    function handleClick(e) {

        setFieldValue('hasAdditionalAddress', Number(e.currentTarget.value));
    }
}

export default AdditionalAddressFormSection;