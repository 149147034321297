import { OtpStatus } from "../types/HomePagePortalTypes";

export const otpInitData = {
    isOtpModalOpen: false,
    modalTitle: '',
    portalAction: '',
    otpCode: '',
    otpStatus: OtpStatus.PERMIT_OR_REQUEST_DETAILS,
    isPermitCanceled: false,
    permitDateValidation: '',
    requestStatus: -1,
}