import React, { useReducer } from "react";

export const COMPONENT_STATE_ACTION = {
    FAIL_STATE: 'fail_state',
    SUCCESS_STATE: 'success_state',
    LOADING_STATE: 'loading_state',
    NOT_LOADING_STATE: 'not_loading_state',
    NO_MESSAGE: 'no_message',
}

function componentReducer(state, action){
    
    switch (action.type) {
        case COMPONENT_STATE_ACTION.FAIL_STATE:
        
            return {...state, 
                isSuccess: false, hasMessage: true, message: action.message, isLoading: false
            };
        case COMPONENT_STATE_ACTION.SUCCESS_STATE:
        
            return {...state, 
                isSuccess: true, hasMessage: true, message: action.message, isLoading: false
            };
        case COMPONENT_STATE_ACTION.LOADING_STATE:

            return {...state, 
                isSuccess: false, hasMessage: false, message: '', isLoading: true
            };
        case COMPONENT_STATE_ACTION.NOT_LOADING_STATE:

            return {...state, isLoading: false};
        case COMPONENT_STATE_ACTION.NO_MESSAGE:
        
            return {...state, 
                isSuccess: true, hasMessage: false, message: '', isLoading: false
            };
        default:
            return state;
    }
} 

export const useComponentState = () => {

    const [componentState, componentStateDispatch] = useReducer(componentReducer, {
        isSuccess: false,
        hasMessage: false,
        message: '',
        isLoading: false
    });
 
    return [componentState, componentStateDispatch];
}