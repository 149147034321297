import React from 'react'
import { usePermitRequestContext } from '../../../../../contexts/PermitRequestContext';
import RadioBtn from '../../../../../components/form/radio/RadioBtn';
import RadioBtnsContainer from '../../../../../components/form/radio/RadioBtnsContainer';
import InputGroup from '../../../../../components/form/InputGroup';

const RentingApartmentFiles = () => {
    const { errors, values, touched, handleBlur, setFieldValue, handleChangeFile } = usePermitRequestContext();

    return (
        <div className='row'>

            <div className='col-12'>

                <RadioBtnsContainer label={'שוכר דירה'}>
                    <RadioBtn
                        isChecked={values.isRentApp}
                        label='כן'
                        value={1}
                        onChange={() => { }}
                        onClick={handleClick}
                        id="isRentAppYes"
                        name='isRentApp'
                    />
                    <RadioBtn
                        isChecked={!values.isRentApp}
                        label='לא'
                        value={0}
                        onChange={() => { }}
                        onClick={handleClick}
                        id="isRentAppNo"
                        name='isRentApp'
                    />
                </RadioBtnsContainer>

            </div>

            {values.isRentApp === 1 ? (
                <>
                    <div className='col-md-6 col-lg-4'>

                        <InputGroup
                            type={'file'}
                            name={'extraFiles.extraFile4'}
                            label={'אנא סרוק חוזה שכירות'}
                            handleBlur={handleBlur}
                            handleChange={(event) => {
                                handleChangeFile('extraFiles.extraFile4', 'extraFile4', event.currentTarget.files[0]);
                            }}
                            isRequired={true}
                            errorMsg={(errors.extraFile4 && touched.extraFile4) ? errors.extraFile4 : undefined}
                        />

                    </div>
                    <div className='col-md-6 col-lg-4'>

                        <InputGroup
                            type={'file'}
                            name={'extraFiles.extraFile5'}
                            label={'סרוק שובר חיוב ארנונה אחרון'}
                            handleBlur={handleBlur}
                            handleChange={(event) => {
                                handleChangeFile('extraFiles.extraFile5', 'extraFile5', event.currentTarget.files[0]);
                            }}
                            isRequired={true}
                            errorMsg={(errors.extraFile5 && touched.extraFile5) ? errors.extraFile5 : undefined}
                        />

                    </div>
                </>


            ) : ''}
        </div>

    );

    function handleClick(e) {
        setFieldValue('isRentApp', Number(e.currentTarget.value));
    }
}

export default RentingApartmentFiles