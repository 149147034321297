import React from 'react';
import { usePermitRequestContext } from '../../../contexts/PermitRequestContext';
import ConfirmModal from '../../../components/modal/ConfirmModal';

const confirmModalTitle = 'האם אתה בטוח?';
const confirmModalContent = 'אין אפשרות לבטל בקשה לאחר שנשלחה.';
const confirmModalContentStyle = { fontSize: '1.35rem' };

const SendReqConfirmModal = () => {

    const { openSendReqConfirmModal, setOpenSendReqConfirmModal, values, submitPermitRequest } = usePermitRequestContext();

    if (!openSendReqConfirmModal)
        return;

    return (

        <ConfirmModal
            title={confirmModalTitle}
            body={(<div style={confirmModalContentStyle}>{confirmModalContent}</div>)}
            apporveFunc={() => onApproval(values)}
            closingFunc={onCancel}
            cancelFunc={onCancel} />
    );

    function onCancel() {
        setOpenSendReqConfirmModal(false);
    }

    function onApproval() {

        setOpenSendReqConfirmModal(false);
        submitPermitRequest();
    }
}

export default SendReqConfirmModal;