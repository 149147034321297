import { createContext, useContext, useReducer } from "react";
import { useFormik } from "formik";
import HomePage from "../pages/home/HomePage";
import { OTP_ACTION, otpReduser } from "../reducers/otpReduser";
import { otpInitData } from "../data/otpInit.data";
import { COMPONENT_STATE_ACTION, useComponentState } from "../hooks/useComponentState";
import { otpFormInit } from "../data/otpFormInit.data";
import { otpFormSchema } from "../validations/otpFormValidation";
import useOtpApi from "../api/useOtpApi";
import ResponseStatus from "../types/ResponseStatus";
import translateErrorCode from "../helpers/errors/translateErrorCode";
import { ActionPortalTypes } from "../types/HomePagePortalTypes";
import { convertFileToSendToServer } from "../helpers/convertors";
import { useProjectConfigContext } from "./ProjectConfigContext";


export const HomePageContext = createContext();


export const HomePageProvider = () => {

    const { loadProjectConfig } = useProjectConfigContext();
    const [otpState, despatchOtp] = useReducer(otpReduser, otpInitData);
    const [homePageState, homePageStateDispatch] = useComponentState();
    const [ modalState, modalStateDispatch ] = useComponentState();
    const {sendOtpCodeApi, checkOtpCodeApi, cancelPermitOtpApi, 
        checkPermitValidationDateOtpApi, checkRequestStatusOtpApi, addFileToRequestOtpApi} = useOtpApi();
    
    const { errors: formErrors, values, touched, handleBlur, handleChange, resetForm } = useFormik({
        initialValues: {...otpFormInit},
        validateOnMount: true,
        validateOnChange: false, 
        validationSchema: otpFormSchema,
    });

    function openOtpModal(modalTitle, portalAction) {

        despatchOtp({
            type: OTP_ACTION.OPEN_MODAL,
            modalTitle: modalTitle,
            portalAction: portalAction,
        });
    }

    function closeOtpModal() {
  
        resetOtpForm();
        
        despatchOtp({
            type: OTP_ACTION.CLOSE_MODAL 
        });
    }

    async function handleCheckOtpCode() {
        modalStateDispatch({type: COMPONENT_STATE_ACTION.LOADING_STATE});
        
        let message = '';
        let success = false;

        try {

            if(otpState.portalAction === ActionPortalTypes.ADD_FILE_TO_REQUEST) {
               
                success = await checkOtpCode(false);
            }else if(otpState.portalAction === ActionPortalTypes.CHECK_REQUEST_STATUS) {
                
                success = await checkRequestStatusOtp();
            }else if(otpState.portalAction === ActionPortalTypes.CANCEL_PERMIT) {
                
                success = await cancelPermitOtp();
            }else if(otpState.portalAction === ActionPortalTypes.CHECK_PEMIT_VALIDITY) {
                
                success = await checkPermitValidationDateOtp();
            }
            
            console.log('COMPONENT_STATE_ACTION.NOT_LOADING_STATE');
            modalStateDispatch({type: COMPONENT_STATE_ACTION.NOT_LOADING_STATE});

        } catch (error) {
            console.log('Error', error);
            if (error.response) {

                message = translateErrorCode(error);
            } else if (error.request) {
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }

            modalStateDispatch({
                type: COMPONENT_STATE_ACTION.FAIL_STATE,
                message: message
            });
        }
    }

    async function checkPermitValidationDateOtp() {

        const dataToSend = {
            personId: values.personId, 
            vehicleNum: values.vehicleNum, 
            otpCode: values.otpCode,
        };
        
        const response = await checkPermitValidationDateOtpApi(dataToSend);
     
        if(!response?.status || response.status !== ResponseStatus.SUCCESS || !response.data?.dateValidationPeriod) {

            throw new Error(response?.message ? response.message : 'בעיה בשרת - לא החזיר תשובה');
        }

        despatchOtp({
            type: OTP_ACTION.OTP_SUCCESS, name: 'permitDateValidation', value: response.data.dateValidationPeriod
        });
        
    }

    async function checkRequestStatusOtp() {
        
        const dataToSend = {
            personId: values.personId, 
            vehicleNum: values.vehicleNum, 
            otpCode: values.otpCode,
        };
        
        const response = await checkRequestStatusOtpApi(dataToSend);
        
        if(!response?.status || response.status !== ResponseStatus.SUCCESS || !response.data?.requestStatus) {
            
            throw new Error(response?.message ? response.message : 'בעיה בשרת - לא החזיר תשובה');
        }

        despatchOtp({
            type: OTP_ACTION.OTP_SUCCESS, name: 'requestStatus', value: response.data.requestStatus
        });

        return true;
    }

    async function cancelPermitOtp() {

        const dataToSend = {
            personId: values.personId, 
            vehicleNum: values.vehicleNum, 
            otpCode: values.otpCode,
        };
        
        const response = await cancelPermitOtpApi(dataToSend);
        
        if(!response?.status || response.status !== ResponseStatus.SUCCESS) {

            throw new Error(response?.message ? response.message : 'בעיה בשרת - לא החזיר תשובה');
        }
    
        if(!response.data?.permitIsCanceled || response.data.permitIsCanceled !== true) {
            throw new Error('תהליך ביטול התו נכשל');
        }

        despatchOtp({
            type: OTP_ACTION.OTP_SUCCESS, name: 'isPermitCanceled', value: 'תו בוטל בהצלחה'
        });
    }

    async function checkOtpCode(isPermit) {

        const dataToSend = { 
            isPermit: isPermit,
            personId: values.personId, 
            vehicleNum: values.vehicleNum, 
            otpCode: values.otpCode,
        };
        
        const response = await checkOtpCodeApi(dataToSend);
        
        if(!response?.status || response.status !== ResponseStatus.SUCCESS) {

            throw new Error(response?.message ? response.message : 'בעיה בשרת - לא החזיר תשובה');
        }
    
        if(!response.data?.isOtpCodeValid || response.data.isOtpCodeValid !== true) {
            throw new Error('אימות נכשל');
        }

        despatchOtp({
            type: OTP_ACTION.OTP_SUCCESS_FILE
        });
    
    }

    async function sendOtpCode() {

        modalStateDispatch({type: COMPONENT_STATE_ACTION.LOADING_STATE});
        let message = '';
        let success = false;

        try {

            let isPermit = true;

            if(otpState.portalAction === ActionPortalTypes.ADD_FILE_TO_REQUEST || otpState.portalAction === ActionPortalTypes.CHECK_REQUEST_STATUS) {
                isPermit = false;
            }

            const dataToSend = {
                isPermit: isPermit,
                personId: values.personId, 
                vehicleNum: values.vehicleNum, 
                otpCode: values.otpCode, 
                portalAction: otpState.portalAction, 
                sendOtpCodeVia: values.sendOtpCodeVia,
            };

            const response = await sendOtpCodeApi(dataToSend);
            const data = response.data;
            
            if(!data?.status || data.status !== ResponseStatus.SUCCESS) {

                throw new Error(data?.message ? data.message : 'בעיה בשרת - לא החזיר תשובה');
            }

            success = true;

            modalStateDispatch({type: COMPONENT_STATE_ACTION.NOT_LOADING_STATE});
            despatchOtp({
                type: OTP_ACTION.OTP_SENT_TO_MOBILE
            });

        } catch (error) {

            const mailOrMobileError = 'פרטי הקשר אינם במערכת';
            if (error.response) {

                message = translateErrorCode(error, {EMAIL_NOT_VALID: mailOrMobileError, MOBILE_NOT_VALID: mailOrMobileError});
            
                loadProjectConfig();
            
            } else if (error.request) {
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }

            modalStateDispatch({
                type: COMPONENT_STATE_ACTION.FAIL_STATE,
                message: message
            });
        }
    }
    
    function resetOtpForm() {
        resetForm();

        despatchOtp({
            type: OTP_ACTION.OTP_RESET
        });

        modalStateDispatch({
            type:COMPONENT_STATE_ACTION.NO_MESSAGE
        });
    }

    async function handleAddFileOtp(file) {
        
        if(!file) {

            return;
        }

        modalStateDispatch({type: COMPONENT_STATE_ACTION.LOADING_STATE});
        let message = '';
        let success = false;

        try {

            const dataTosent = {
                personId: values.personId, 
                vehicleNum: values.vehicleNum, 
                otpCode: values.otpCode,
                file: null
            }

            const fileData = await convertFileToSendToServer(file);

            dataTosent.file = fileData
            const response = await addFileToRequestOtpApi(dataTosent);
            
            console.log(response)
            if(!response?.status || response.status !== ResponseStatus.SUCCESS) {

                throw new Error(response?.message ? response.message : 'בעיה בשרת - לא החזיר תשובה');
            }

            success = true;

            modalStateDispatch({type: COMPONENT_STATE_ACTION.NOT_LOADING_STATE});
            despatchOtp({
                type: OTP_ACTION.OTP_SUCCESS_FILE
            });

            homePageStateDispatch({
                type: COMPONENT_STATE_ACTION.SUCCESS_STATE, message: 'קובץ עלה בהצלחה'
            });

            closeOtpModal();
        } catch (error) {

            if (error.response) {

                message = translateErrorCode(error);

            } else if (error.request) {
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }

            modalStateDispatch({
                type: COMPONENT_STATE_ACTION.FAIL_STATE,
                message: message
            });
        }
    }
    
    return (
        <HomePageContext.Provider value={{ otpState, closeOtpModal, openOtpModal, 
            handleCheckOtpCode, sendOtpCode, resetOtpForm, homePageState, handleChange, handleBlur,
             values, formErrors, touched, modalState,
            handleAddFileOtp }}>
            <HomePage />
        </HomePageContext.Provider>
    );
}

export const useHomePageContext = () =>  useContext(HomePageContext);