import { OtpStatus } from "../types/HomePagePortalTypes";

export const OTP_ACTION = {
    CLOSE_MODAL: 'closeModal',
    OPEN_MODAL: 'openModal',
    OTP_SENT_TO_MOBILE: 'otpSentToMobile',
    OTP_RESET: 'otpReset',
    OTP_SUCCESS: 'otpSuccess',
    OTP_SUCCESS_FILE: 'otpSuccessFile',
}
//
export function otpReduser(state, action) {

    switch (action.type) {
        case OTP_ACTION.CLOSE_MODAL:
            return {...state, isOtpModalOpen: false, modalTitle: ''};
        case OTP_ACTION.OPEN_MODAL:
            return {...state, isOtpModalOpen: true, modalTitle: action.modalTitle, portalAction: action.portalAction};
        case OTP_ACTION.OTP_SENT_TO_MOBILE:
            return {...state, otpStatus: OtpStatus.OTP_SENT_TO_MOBILE};
        case OTP_ACTION.OTP_RESET:
            return {...state, otpStatus: OtpStatus.PERMIT_OR_REQUEST_DETAILS};
        case OTP_ACTION.OTP_SUCCESS:
            return {...state, otpStatus: OtpStatus.OTP_SUCCESS, [action.name]: action.value};
        case OTP_ACTION.OTP_SUCCESS_FILE:
            return {...state, otpStatus: OtpStatus.OTP_SUCCESS};
        default:
            return state;
    }
}