export const AUTH_ACTION = {
    SET_TOKEN: 'SET_TOKEN',
}

export function authReducer(state, action) {

    switch (action.type) {
        case AUTH_ACTION.SET_TOKEN:
            return {
                ...state, 
                token: action.payload.token,
                loggin: action.payload.loggin,
            };
        default:
            return state;
    }
}
