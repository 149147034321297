import React from 'react';
import SectionTitle from '../SectionTitle';
import Style from '../permitRequest.module.css';
import SelectInput from '../../../../components/form/select/SelectInput';
import InputGroup from '../../../../components/form/InputGroup';
import { usePermitRequestContext } from '../../../../contexts/PermitRequestContext';
import FormSection from './FormSection';

const years = [
    { value: '-1', name: 'בחר' },
    { value: '24', name: '2024' },
    { value: '25', name: '2025' },
    { value: '26', name: '2026' },
    { value: '27', name: '2027' },
    { value: '28', name: '2028' },
    { value: '29', name: '2029' },
    { value: '30', name: '2030' }
];

const months = [
    { value: '-1', name: 'בחר' },
    { value: '01', name: '01' },
    { value: '02', name: '02' },
    { value: '03', name: '03' },
    { value: '04', name: '04' },
    { value: '05', name: '05' },
    { value: '06', name: '06' },
    { value: '07', name: '07' },
    { value: '08', name: '08' },
    { value: '09', name: '09' },
    { value: '10', name: '10' },
    { value: '11', name: '11' },
    { value: '12', name: '12' }
];

function CreditCardFormSection() {

    const { handleChange, errors, values, touched, handleBlur } = usePermitRequestContext();
    const payment = values.paymentAmount;
    const craditCardDetails = values.craditCard;
    const craditCardErrors = errors.craditCard ? errors.craditCard : {};
    const craditCardTouched = touched.craditCard ? touched.craditCard : {};

    const hasPayment = payment > 0 ? true : false;
    const title = `פרטי התשלום(₪${payment})`;
    const subTitle = 'מלאו את הפרטים הבאים בצורה מפורטת שיסייעו לנו בטיפול בפנייה';

    if (!hasPayment) {
        return '';
    }

    return (
        <FormSection>
            <SectionTitle title={title} subTitle={subTitle} />

            <div className='row'>
                <div className='col-12 col-xs-12 col-md-4 col-lg-4 col-lg-3'>
                    <InputGroup
                        name={'craditCard.cardNumber'}
                        label={'מספר כרטיס אשראי'}
                        value={craditCardDetails.cardNumber}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        isRequired={true}
                        errorMsg={(craditCardErrors.cardNumber && craditCardTouched.cardNumber) ? craditCardErrors.cardNumber : undefined}
                    />
                </div>
                <div className='col-4 col-xs-4 col-md-3 col-lg-3 col-lg-2'>
                    <SelectInput
                        name={'craditCard.cardExpireDateYY'}
                        label={'תוקף שנה'}
                        value={craditCardDetails.cardExpireDateYY}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        selects={years}
                        isRequired={true}
                        errorMsg={(craditCardErrors.cardExpireDateYY && craditCardTouched.cardExpireDateYY) ? craditCardErrors.cardExpireDateYY : undefined}
                    />
                </div>
                <div className='col-4 col-xs-4 col-md-3 col-lg-3 col-lg-2'>
                    <SelectInput
                        name={'craditCard.cardExpireDateMM'}
                        label={'תוקף חודש'}
                        value={craditCardDetails.cardExpireDateMM}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        selects={months}
                        isRequired={true}
                        errorMsg={(craditCardErrors.cardExpireDateMM && craditCardTouched.cardExpireDateMM) ? craditCardErrors.cardExpireDateMM : undefined}
                    />
                </div>
                <div className='col-4 col-xs-4 col-md-2 col-lg-2 col-lg-1'>
                    <InputGroup
                        name={'craditCard.cardAddedNumber'}
                        label={'CVV'}
                        value={craditCardDetails.cardAddedNumber}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        isRequired={true}
                        errorMsg={(craditCardErrors.cardAddedNumber && craditCardTouched.cardAddedNumber) ? craditCardErrors.cardAddedNumber : undefined}
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col-12 col-sm-12 col-md-4 col-lg-4 col-lg-3'>
                    <InputGroup
                        name={'craditCard.cardHolderId'}
                        label={'תעודת זהות של בעל הכרטיס'}
                        value={craditCardDetails.cardHolderId}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        isRequired={true}
                        errorMsg={(craditCardErrors.cardHolderId && craditCardTouched.cardHolderId) ? craditCardErrors.cardHolderId : undefined}
                    />
                </div>
            </div>
        </FormSection>
    );
}

CreditCardFormSection.defaultProps = {
    isChecked: false
}

export default CreditCardFormSection;