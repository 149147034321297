import React from 'react';
import InputGroup from '../../../../components/form/InputGroup'
import { useHomePageContext } from '../../../../contexts/HomePageContext';
import style from '../../homePage.module.css';
import { ActionPortalTypes, OtpStatus } from '../../../../types/HomePagePortalTypes';
import SendOtpCodeToMobileBtn from './SendOtpCodeToMobileBtn';
import CheckOtpCodeFromMobileBtn from './CheckOtpCodeFromMobileBtn';
import ResetPermitDetailsBtn from './ResetPermitDetailsBtn';
import CanceledPermitWarning from './CanceledPermitWarning';


const OtpForm = () => {

  const { otpState, handleChange, handleBlur, values, formErrors, touched } = useHomePageContext();

  const disabled = (Object.keys(formErrors).length > 0);
  let disabledInputPermitDetails = false;
  let showOtpInput = false;
  let text = 'לצורך זיהוי ישלח קוד למספר הנייד שהוזן בעת מילוי בקשת התו';
  let btnsDiv = '';
  let otpLabel = 'אנא הכנס את הקוד שהתקבל בהודעת SMS';

  if (values.sendOtpCodeVia === 'EMAIL') {
    otpLabel = 'אנא הכנס את הקוד שהתקבל במייל';
    text = 'לצורך זיהוי ישלח קוד לכתובת המייל שהוזן בעת מילוי בקשת התו.';
  }

  if (otpState.otpStatus === OtpStatus.OTP_SENT_TO_MOBILE) {
    disabledInputPermitDetails = true;
    showOtpInput = true;

    btnsDiv = (
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
        <CheckOtpCodeFromMobileBtn disabled={disabled} />
        <ResetPermitDetailsBtn disabled={disabled} />
      </div>
    );
  } else if (otpState.otpStatus === OtpStatus.PERMIT_OR_REQUEST_DETAILS) {
    btnsDiv = (
      <>
        <p>{text}</p>
        <SendOtpCodeToMobileBtn disabled={disabled} />
      </>
    );
  }

  return (
    <div className={style.otpForm}>

      {showOtpInput ? (
        <div className='row mt-4'>
          <div className='col-12 col-sm-8'>
            <InputGroup
              value={values.otpCode}
              name='otpCode'
              label={otpLabel}
              handleChange={handleChange}
              handleBlur={handleBlur}
              errorMsg={formErrors.otpCode && touched.otpCode && formErrors.otpCode}
            />
          </div>
        </div>
      ) : ''}
      <div className='row'>
        <div className='col-12 col-sm-6'>
          <InputGroup
            value={values.vehicleNum}
            name='vehicleNum'
            label='מספר רכב'
            handleChange={handleChange}
            handleBlur={handleBlur}
            disabled={disabledInputPermitDetails}
            errorMsg={(formErrors.vehicleNum && touched.vehicleNum) && formErrors.vehicleNum}
          />
        </div>

        <div className='col-12 col-sm-6'>
          <InputGroup
            value={values.personId}
            name='personId'
            label='מספר תעודת זהות'
            handleChange={handleChange}
            handleBlur={handleBlur}
            disabled={disabledInputPermitDetails}
            errorMsg={(formErrors.personId && touched.personId) && formErrors.personId}
          />
        </div>
      </div>
      {otpState.portalAction === ActionPortalTypes.CANCEL_PERMIT ? (
        <div className='row mt-2'>
          <div className='col-12'>
            <CanceledPermitWarning />
          </div>
        </div>
      ) : ''}
      <div className='row mt-4'>
        <div className='col-12'>
          {btnsDiv}
        </div>
      </div>
    </div>
  );


}

export default OtpForm