import React, { useEffect, useState } from 'react';
import { usePermitRequestContext } from '../../../../contexts/PermitRequestContext';
import InputGroup from '../../../../components/form/InputGroup';
import RadioBtnsContainer from '../../../../components/form/radio/RadioBtnsContainer';
import RadioBtn from '../../../../components/form/radio/RadioBtn';
import SelectCarMaker from '../../../../components/form/select/SelectCarMaker';
import SelectCarColor from '../../../../components/form/select/SelectCarColor';
import OwnershipCarType from '../../../../types/OwnershipCarType';
import SectionTitle from '../SectionTitle';
import FormSection from './FormSection';

function CarDetailsFormSection() {

    const { handleChange, errors, values, touched,
        handleBlur, setFieldValue, setFieldTouched, handleChangeFile } = usePermitRequestContext();
    const [radioBtnState, setChecked] = useState([true, false]);

    const carDetails = values.carDetails;
    const carDetailsErrors = errors.carDetails ? errors.carDetails : {};
    const carDetailsTouched = touched.carDetails ? touched.carDetails : {};

    let inputWorkStatment = '';

    useEffect(() => {

        setChecked([carDetails.ownershipCarType === OwnershipCarType.PRIVATE ? true : false, carDetails.ownershipCarType === OwnershipCarType.COMPANY ? true : false]);
    }, [carDetails.ownershipCarType]);

    if (carDetails.ownershipCarType === OwnershipCarType.COMPANY) {

        inputWorkStatment = (
            <div className='col-md-6 col-lg-4'>
                <InputGroup
                    type={'file'}
                    name={'carDetails.scanWorkStatment'}
                    label={'צילום הצהרה ממקום העבודה/מחברת הליסינג'}
                    handleBlur={handleBlur}
                    handleChange={(event) => {
                        handleChangeFile('carDetails.scanWorkStatment', 'scanWorkStatment', event.currentTarget.files[0]);
                    }}
                    isRequired={true}
                    errorMsg={(carDetailsErrors.scanWorkStatment && carDetailsTouched.scanWorkStatment) ? carDetailsErrors.scanWorkStatment : undefined}
                />
            </div>
        );
    }

    return (
        <FormSection>
            <SectionTitle title='פרטי הרכב' />
            <div className='row align-items-end mb-1'>
                <div className='col-md-6 col-lg-4'>
                    <InputGroup
                        name={'carDetails.carId'}
                        label={'מספר רכב'}
                        type='number'
                        value={carDetails.carId}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        isRequired={true}
                        errorMsg={(carDetailsErrors.carId && carDetailsTouched.carId) ? carDetailsErrors.carId : undefined}
                    />
                </div>
                <div className='col-md-6 col-lg-4'>
                    <RadioBtnsContainer label={'בעלות רכב'}>
                        <RadioBtn
                            isChecked={radioBtnState[0]}
                            label='רכב פרטי'
                            value={OwnershipCarType.PRIVATE}
                            onChange={() => { }}
                            onClick={handleClick}
                            id="ownershipCarTypeYes"
                            name='carDetails.ownershipCarType'
                        />
                        <RadioBtn
                            isChecked={radioBtnState[1]}
                            label='רכב חברה'
                            value={OwnershipCarType.COMPANY}
                            onChange={() => { }}
                            onClick={handleClick}
                            id="ownershipCarTypeNo"
                            name='carDetails.ownershipCarType'
                        />
                    </RadioBtnsContainer>
                </div>

            </div>
            <div className='row mb-1'>
                <div className='col-12 col-md-6 col-lg-4'>
                    <InputGroup
                        type={'file'}
                        name={'carDetails.scanCarId'}
                        label={'צילום רשיון רכב'}
                        handleBlur={handleBlur}
                        handleChange={(event) => {
                            handleChangeFile('carDetails.scanCarId', 'scanCarId', event.currentTarget.files[0]);
                        }}
                        isRequired={true}
                        errorMsg={(carDetailsErrors.scanCarId && carDetailsTouched.scanCarId) ? carDetailsErrors.scanCarId : undefined}
                    />
                </div>
                {inputWorkStatment}
            </div>
            {carDetails.hasColorAndType ? (

                <div className='row'>
                    <div className='col-md-6 col-lg-4'>
                        <SelectCarColor
                            name={'carDetails.vColor'}
                            value={carDetails.vColor}
                            isRequired={true}
                            handleChange={option => setFieldValue('carDetails.vColor', option.value)}
                            handleBlur={e => {
                                handleBlur(e);
                                setFieldTouched('carDetails.vColor', true);
                            }}
                            errorMsg={(carDetailsErrors.vColor && carDetailsTouched.vColor) ? carDetailsErrors.vColor : undefined}
                        />
                    </div>
                    <div className='col-md-6 col-lg-4'>
                        <SelectCarMaker
                            name={'carDetails.vType'}
                            value={carDetails.vType}
                            isRequired={true}
                            handleChange={option => setFieldValue('carDetails.vType', option.value)}
                            handleBlur={e => {
                                handleBlur(e);
                                setFieldTouched('carDetails.vType', true);
                            }}
                            errorMsg={(carDetailsErrors.vType && carDetailsTouched.vType) ? carDetailsErrors.vType : undefined}
                        />
                    </div>
                </div>
            ) : ''}
        </FormSection>
    );

    function handleClick(e) {
        setFieldValue('carDetails.ownershipCarType', Number(e.currentTarget.value));
    }
}

CarDetailsFormSection.defaultProps = {
    isChecked: false
}

export default CarDetailsFormSection;