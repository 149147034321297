import { axiosCitizenSticker } from "./api";
import { useProjectConfigContext } from "../contexts/ProjectConfigContext";

export const VALID_PERMIT_ALREADY_EXIST_ERROR = {
    code: 1,
    desplayMsg: 'כבר קיים תו במערכת'
}

export const VALID_REQUEST_ALREADY_EXIST = {
    code: 2,
    desplayMsg: 'קיימת כבר בקשה עם הנתונים'
}

export const FILES_NOT_VALID = {
    code: 3,
    desplayMsg: 'התרחשה בעיה בתהליך עיבוד הקבצים - הבקשה לא התקבלה'
}

export const GENERAL = {
    code: 4,
    desplayMsg: 'בעיה כללית בשרת - הבקשה לא התקבלה'
}

export const BAD_REQUEST = {
    code: 5,
    desplayMsg: 'נוצרה בעיה בגלל הנתונים השתקבלו'
}

export const TRANZILA_PAYMENT_PROCESS_FAIL = {
    code: 11,
    desplayMsg: (
        <>
            בעיה בתהליך הסליקה - בעיה בתהליך סליקת כרטיס האשראי. אנא בדוק תקינות כרטיס האשראי.
            <br />
            במידת הצורך, אנא פנה לחברת האשראי לצורך בדיקה
        </>),
}

export const ENABLE_PAYMENT_TRANSFER = {
    code: 12,
    desplayMsg: 'התרחשה בעיה בתהליך עיבוד הקבצים - הבקשה לא התקבלה'
}

export const GENERAL_PAYMENT_ERROR = {
    code: 13,
    desplayMsg: 'בעיה בתהליך הסליקה - הבקשה לא התקבלה'
}

export const BAD_PAYMENT_REQUEST = {
    code: 14,
    desplayMsg: 'בעיה בתהליך הסליקה - לא כל הנתונים התקבלו להשלמת התהליך'
}

export const ADD_REQUEST_ERROR_API = {
    [VALID_PERMIT_ALREADY_EXIST_ERROR.code]: VALID_PERMIT_ALREADY_EXIST_ERROR.desplayMsg,
    [VALID_REQUEST_ALREADY_EXIST.code]: VALID_REQUEST_ALREADY_EXIST.desplayMsg,
    [FILES_NOT_VALID.code]: FILES_NOT_VALID.desplayMsg,
    [GENERAL.code]: GENERAL.desplayMsg,
    [BAD_REQUEST.code]: BAD_REQUEST.desplayMsg,
    [TRANZILA_PAYMENT_PROCESS_FAIL.code]: TRANZILA_PAYMENT_PROCESS_FAIL.desplayMsg,
    [ENABLE_PAYMENT_TRANSFER.code]: ENABLE_PAYMENT_TRANSFER.desplayMsg,
    [GENERAL_PAYMENT_ERROR.code]: GENERAL_PAYMENT_ERROR.desplayMsg,
    [BAD_PAYMENT_REQUEST.code]: BAD_PAYMENT_REQUEST.desplayMsg,
}

export default function useAddPermitRequestApi() {

    const { authState } = useProjectConfigContext();

    async function addPermitRequestApi(formData) {

        const config = {
            headers: { Authorization: `Bearer ${authState.token}` }
        };

        const reqToSend = JSON.parse(JSON.stringify(formData));
        console.log(`reqToSend: ${reqToSend}`)

        const response = await axiosCitizenSticker.post('/addCitizenSticker', reqToSend, config);
        return await response.data;
    }

    return addPermitRequestApi;
}