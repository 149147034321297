import { useReducer } from "react";
import { initConfirmationModal } from "../data/initConfirmationModal.data";
import { FORM_ACTION, successAddRequestModalReduser } from "../reducers/successAddRequestModalReduser";


export const useAddReqConclusionModal = () => {

    const [conclusionModalState, confModalStateDispatch] = useReducer(successAddRequestModalReduser, initConfirmationModal);

    function closeAddReqConclusionModal() {
        confModalStateDispatch({
            type: FORM_ACTION.CLOSE_MODAL
        });
    }

    function openAddReqConclusionModal() {
        confModalStateDispatch({
            type: FORM_ACTION.OPEN_MODAL
        });
    }

    function setDataAddReqConclusionModal(asmachta, permitRequestId, amountPayed, hasReceipt, receiptUrl) {

        confModalStateDispatch({
            type: FORM_ACTION.UPDATE_DATA,
            asmachta: asmachta,
            permitRequestId: permitRequestId,
            amountPayed: amountPayed,
            isModalOpen: true,
            hasReceipt: hasReceipt,
            receiptUrl: receiptUrl,
        });
    }

 
    return {closeAddReqConclusionModal, openAddReqConclusionModal, setDataAddReqConclusionModal, conclusionModalState};
}