import React from 'react';
import RadioBtnsContainer from '../../../../components/form/radio/RadioBtnsContainer';
import RadioBtn from '../../../../components/form/radio/RadioBtn';
import PermitType from '../../../../types/PermitType';

const DisabilityCardPermit = ({permitType, handleClick}) => {

  const label = `בעל תו נכה?`;

  return (
    <div className='row'> 
        <div className='col col-sm-4'>
            <RadioBtnsContainer label={label}>
                <RadioBtn 
                isChecked={permitType !== PermitType.HANDICAPPED}
                label='לא'
                value={PermitType.REGULAR}
                onChange={() => {}}
                onClick={handleClick}
                id="disabilityCardYes"
                name='disabilityCard'
                />
                <RadioBtn
                isChecked={permitType === PermitType.HANDICAPPED}
                label='כן' 
                value={PermitType.HANDICAPPED}
                onChange={() => {}}
                onClick={handleClick}
                id="disabilityCardNo"
                name='disabilityCard'
                />
            </RadioBtnsContainer>
        </div>
    </div>
  );
}

export default DisabilityCardPermit;