import React from 'react'
import { useHomePageContext } from '../../../../contexts/HomePageContext';
import Button from '../../../../components/buttons/Button';

const DisplayPermitValidationDate = () => {

  const { otpState, closeOtpModal } = useHomePageContext();

  let text = 'תוקף התו עד תאריך  ' + otpState.permitDateValidation;


  return (
    <div className='row'>
      <div className='col-12 mb-2'>{text}</div>
      <div className='col-12'><Button  onClick={closeOtpModal} text='אישור' /></div>
    </div>
  );
}

export default DisplayPermitValidationDate