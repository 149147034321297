import * as yup from 'yup';
import {  MAX_FILE_SIZE, MAX_FILE_SIZE_MESSAGE, REQUIRED_FIELD_MESSAGE, SUPPORTED_FORMATS, TYPE_FILE_MESSAGE } from '../errorMessages';

export const yupObjectFiles = {
    extraFile4: yup.mixed()
        .when("isRentApp", (isRentApp, schema) => {

            if (isRentApp == 0 || isRentApp == '0') {
                return schema.notRequired();
            }

            return (
                schema
                    .nonNullable()
                    .required(REQUIRED_FIELD_MESSAGE)
                    .test(
                        "FILE_SIZE",
                        MAX_FILE_SIZE_MESSAGE,
                        (value) => {

                            return !value || (value && value.size <= MAX_FILE_SIZE)
                        }
                    )
                    .test(
                        "FILE_FORMAT",
                        TYPE_FILE_MESSAGE,
                        (value) => {

                            return !value || (value && SUPPORTED_FORMATS.includes(value?.contentType))
                        }
                    )
            );
        }),
    extraFile5: yup.mixed()
        .when("isRentApp", (isRentApp, schema) => {

            if (isRentApp == 0 || isRentApp == '0') {
                return schema.notRequired();
            }

            return (
                schema
                    .nonNullable()
                    .required(REQUIRED_FIELD_MESSAGE)
                    .test(
                        "FILE_SIZE",
                        MAX_FILE_SIZE_MESSAGE,
                        (value) => {

                            return !value || (value && value.size <= MAX_FILE_SIZE)
                        }
                    )
                    .test(
                        "FILE_FORMAT",
                        TYPE_FILE_MESSAGE,
                        (value) => {

                            return !value || (value && SUPPORTED_FORMATS.includes(value?.contentType))
                        }
                    )
            );
        }),
    extraFile2: yup.mixed()
        .when("hasBusiness", (hasBusiness, schema) => {

            if (hasBusiness == 0 || hasBusiness == '0') {
                return schema.notRequired();
            }

            return (
                schema
                    .nonNullable()
                    .required(REQUIRED_FIELD_MESSAGE)
                    .test(
                        "FILE_SIZE",
                        MAX_FILE_SIZE_MESSAGE,
                        (value) => {

                            return !value || (value && value.size <= MAX_FILE_SIZE)
                        }
                    )
                    .test(
                        "FILE_FORMAT",
                        TYPE_FILE_MESSAGE,
                        (value) => {

                            return !value || (value && SUPPORTED_FORMATS.includes(value?.contentType))
                        }
                    )
            );
        }),
    extraFile3: yup.mixed()
        .when("hasBusiness", (hasBusiness, schema) => {

            if (hasBusiness == 0 || hasBusiness == '0') {
                return schema.notRequired();
            }

            return (
                schema
                    .nonNullable()
                    .required(REQUIRED_FIELD_MESSAGE)
                    .test(
                        "FILE_SIZE",
                        MAX_FILE_SIZE_MESSAGE,
                        (value) => {

                            return !value || (value && value.size <= MAX_FILE_SIZE)
                        }
                    )
                    .test(
                        "FILE_FORMAT",
                        TYPE_FILE_MESSAGE,
                        (value) => {

                            return !value || (value && SUPPORTED_FORMATS.includes(value?.contentType))
                        }
                    )
            );
        }),
}