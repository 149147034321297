import React from 'react';
import Alert from './Alert';

const DangerAlert = ({ message, isShow, onClose, subject }) => {

    return <Alert type={'danger'}
        message={message}
        isShow={isShow}
        onClose={onClose}
        subject={subject}
    />;
}

export default DangerAlert;