import React from 'react'
import { usePermitRequestContext } from '../../../../../contexts/PermitRequestContext';
import RadioBtn from '../../../../../components/form/radio/RadioBtn';
import RadioBtnsContainer from '../../../../../components/form/radio/RadioBtnsContainer';
import InputGroup from '../../../../../components/form/InputGroup';

const HasBusinessFiles = () => {

    const { errors, values, touched, handleBlur, setFieldValue, handleChangeFile } = usePermitRequestContext();

    return (
        <div className='row'>

            <div className='col-12'>

                <RadioBtnsContainer label={'עסק רשום בעיר?'}>
                    <RadioBtn
                        isChecked={values.hasBusiness}
                        label='כן'
                        value={1}
                        onChange={() => { }}
                        onClick={handleClick}
                        id="hasBusinessYes"
                        name='hasBusiness'
                    />
                    <RadioBtn
                        isChecked={!values.hasBusiness}
                        label='לא'
                        value={0}
                        onChange={() => { }}
                        onClick={handleClick}
                        id="hasBusinessNo"
                        name='hasBusiness'
                    />
                </RadioBtnsContainer>

            </div>

            {values.hasBusiness === 1 ? (
                <>
                    <div className='col-md-6 col-lg-4'>

                        <InputGroup
                            type={'file'}
                            name={'extraFiles.extraFile2'}
                            label={'אנא סרוק תעודת עוסקת מורשה'}
                            handleBlur={handleBlur}
                            handleChange={(event) => {
                                handleChangeFile('extraFiles.extraFile2', 'extraFile2', event.currentTarget.files[0]);
                            }}
                            isRequired={true}
                            errorMsg={(errors.extraFile2 && touched.extraFile2) ? errors.extraFile2 : undefined}
                        />
                        {errors.extraFile2}
                    </div>
                    <div className='col-md-6 col-lg-4'>

                        <InputGroup
                            type={'file'}
                            name={'extraFiles.extraFile3'}
                            label={'סרוק ארנונה על שם העסק'}
                            handleBlur={handleBlur}
                            handleChange={(event) => {
                                handleChangeFile('extraFiles.extraFile3', 'extraFile3', event.currentTarget.files[0]);
                            }}
                            isRequired={true}
                            errorMsg={(errors.extraFile3 && touched.extraFile3) ? errors.extraFile3 : undefined}
                        />

                    </div>
                </>


            ) : ''}

        </div>

    );


    function handleClick(e) {
        setFieldValue('hasBusiness', Number(e.currentTarget.value));
    }
}

export default HasBusinessFiles;