export const initProjectConfigData = {
    projectId: -1,
    colors: [],
    makers: [],
    streets: [],
    tdsConfig: {
        gilHaZahavAge: false,
        colorAndType: false,
        gilHaZahavOption: false,
        studentOption: false,
        gilHaZahavPayment: false,
        gilHaZahavValidityPeriod: false,
        payment: false,
        studentPayment: false,
        studentValidityPeriod: false,
        validityPeriod: false,
        handicappedOption: false,
    },
    hasSession: false,
    hasTopDeclaration: false,
    hasAdditionalAddress: false,
    extraFile: false,
    rantKiriaBialikFile: false,
    contryClubFile: false,
    extraApartmentFiles: false,
    extraBusinessFiles: false,
    cityHebName: '',
    cityLogoPath: '',
}