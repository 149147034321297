import React from 'react';
import Select from 'react-select';
import RedStar from '../RedStar';

const SelectWithSearch = ({ name, label, errorMsg, selects, isRequired, handleChange, handleBlur, value }) => {
    let classes = '';
    const placeholder = 'צריך לבחור';
    
    const hasError = errorMsg ? true : false;

    if (hasError) {
        classes = ' is-invalid';
    }
    
    return (
        <>
            <label className="form-label" htmlFor={`${name}id`}>
                {label}
                {isRequired && <RedStar />}
            </label>
            <Select
            placeholder={placeholder}
            className={classes}  
            isRtl={true} 
            options={selects} 
            onChange={(option) => handleChange(option)} 
            onBlur={(e) => handleBlur(e)} 
            value={value}
            styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? '#0d6efd' : (hasError ? 'red' : '#dee2e6'),
                }),
            }}
              />
            {hasError ? <div id='asas' className="invalid-feedback"> {errorMsg} </div> : ''}
        </>
    );
}
// defaultValue={-1}
export default SelectWithSearch