import React from 'react'
import { useHomePageContext } from '../../../../contexts/HomePageContext';
import Button from '../../../../components/buttons/Button';

const DisplayPermitIsCanceled = () => {
  const { otpState, closeOtpModal } = useHomePageContext();

  let text = 'תהליך ביטול התו הושלם בהצלחה';


  return (
    <div className='row'>
      <div className='col-12 mb-2'>{text}</div>
      <div className='col-12'><Button onClick={closeOtpModal} text='אישור' /></div>
    </div>
  );
}

export default DisplayPermitIsCanceled