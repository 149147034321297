import React from 'react';
import translateHtmlUncodeToChar from '../../../helpers/language/TranslateHtmlUncodeToChar';
import { useProjectConfigContext } from '../../../contexts/ProjectConfigContext';
import SelectWithSearch from './SelectWithSearch';

const SelectStreet = ({ name, handleBlur, handleChange, errorMsg, value }) => {
    const { projectConfigState } = useProjectConfigContext();
    let streets = [{ value: -1, label: 'אין אופציות' }];

    if (projectConfigState.streets !== undefined) {

        const streetsList = projectConfigState.streets;
        streets = streetsList.map(street => {
            return { label: translateHtmlUncodeToChar(street.name), value: street.id }
        });

        streets = [{ value: -1, label: 'צריך לבחור' }, ...streets];
    }

    const valueStr = streets.findLast(option => option.value == value);

    return (
        <SelectWithSearch
            name={name}
            label={'רחוב'}
            selects={streets}
            isRequired={true}
            handleBlur={handleBlur}
            handleChange={handleChange}
            value={valueStr}
            errorMsg={errorMsg}
        />
    );
}

export default SelectStreet; 