import React from 'react';
import RadioBtnsContainer from '../../../../components/form/radio/RadioBtnsContainer';
import RadioBtn from '../../../../components/form/radio/RadioBtn';
import { useProjectConfigContext } from '../../../../contexts/ProjectConfigContext';
import PermitType from '../../../../types/PermitType';

const GoldenAgePermit = ({permitType, handleClick}) => {

  const { projectConfigState } = useProjectConfigContext();
  
  const tdsConfig = projectConfigState.tdsConfig;
  const gilHaZahavAge = tdsConfig.gilHaZahavAge;

  const label = `מעל גיל ${gilHaZahavAge}?`;

  return (
    <div className='row'> 
        <div className='col col-sm-4'>
            <RadioBtnsContainer label={label}>
                <RadioBtn 
                isChecked={permitType !== PermitType.GOLDEN}
                label='לא'
                value={PermitType.REGULAR}
                onChange={() => {}}
                onClick={handleClick}
                id="goldenPermitYes"
                name='permitTypeGolden'
                />
                <RadioBtn
                isChecked={permitType === PermitType.GOLDEN}
                label='כן' 
                value={PermitType.GOLDEN}
                onChange={() => {}}
                onClick={handleClick}
                id="goldenPermitNo"
                name='permitTypeGolden'
                />
            </RadioBtnsContainer>
        </div>
    </div>
  );
}

export default GoldenAgePermit