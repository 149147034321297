import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import App from './App';
import { PageStateProvider } from './contexts/PageStateContext';
import { ProjectConfigProvider } from './contexts/ProjectConfigContext';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Router>
      <ProjectConfigProvider>
        <PageStateProvider>
          <App />
        </PageStateProvider>
      </ProjectConfigProvider>
    </Router>
  </React.StrictMode>
);