import React from 'react'
import { useHomePageContext } from '../../../../contexts/HomePageContext';
import RequestStatusType from '../../../../types/RequestStatusType';
import Button from '../../../../components/buttons/Button';

const DisplayRequestStatus = () => {

  const { otpState, closeOtpModal } = useHomePageContext();

  let text = 'אין נתונים';

  if(RequestStatusType.APPROVED === otpState.requestStatus) {
    text = 'הבקשה אושרה.';
  } else if(RequestStatusType.PENDING === otpState.requestStatus) {
    text = (
      <p>
      הבקשה ממתינה לאישור.
      <br/>
      לאחר אישור הבקשה ישלח מייל אישור.
      </p>
      );
  } else if(RequestStatusType.REJECTED === otpState.requestStatus) {
    text = 'הבקשה נדחתה.';
  }

  return (
    <div className='row'>
      <div className='col-12 mb-2'>{text}</div>
      <div className='col-12'><Button onClick={closeOtpModal} text='אישור'/></div>
    </div>
  );
}

export default DisplayRequestStatus