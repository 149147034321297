import { initConfirmationModal } from "../data/initConfirmationModal.data";

export const FORM_ACTION = {
    CLOSE_MODAL: 'CLOSE_MODAL',
    OPEN_MODAL: 'OPEN_MODAL',
    UPDATE_DATA:'UPDATE_DATA',
}


export function successAddRequestModalReduser(state, action){

    if(action.type === FORM_ACTION.OPEN_MODAL) {

        return {...state, isModalOpen: true};
    }else if(action.type === FORM_ACTION.CLOSE_MODAL) {
        
        return {...state, 
            asmachta: initConfirmationModal.asmachta,
            permitRequestId: initConfirmationModal.permitRequestId,
            amountPayed: initConfirmationModal.amountPayed,
            hasReceipt: initConfirmationModal.hasReceipt,
            receiptUrl: initConfirmationModal.receiptUrl,
            isModalOpen: false,
        };
    }else if(action.type === FORM_ACTION.UPDATE_DATA) {

        return {...state,
            asmachta: action.asmachta,
            permitRequestId: action.permitRequestId,
            amountPayed: action.amountPayed,
            isModalOpen: action.isModalOpen,
            hasReceipt: action.hasReceipt,
            receiptUrl: action.receiptUrl,
        };
    }else{

        return state;
    } 
}