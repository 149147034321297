import React from 'react'
import RedStar from './RedStar';

const InputGroup = ({ label, value, name, handleChange, handleBlur, errorMsg, isRequired, type, disabled }) => {

    let classes = 'form-control';
    const hasError = errorMsg ? true : false;

    if(hasError){
        classes += ' is-invalid';
    }

    return (
        <>
            <label className="form-label">
                <div>
                {label}
                {isRequired ? <RedStar /> : ''}
                </div>
            </label>
           
            { 
            type === 'file' ? <input type={type} disabled={disabled} onBlur={handleBlur} className={classes} onChange={handleChange} name={name} /> :
                <input type={type} disabled={disabled} onBlur={handleBlur} className={classes} onChange={ (event) => handleChange(event)} name={name} value={value} />
            }
            {hasError ? <div id='asas' className="invalid-feedback"> {errorMsg} </div> : ''}
        </>
    )
}

InputGroup.defaultProps = {

    type: 'text',
    value: '',
    isRequired: false,
    disabled: false,
    errorMsg: '',
    handleChange: () => { },
    updateIsTouch: () => { }
}

export default InputGroup