import React, { createContext, useReducer } from 'react';


export const PAGE_STATE_ACTION = {
    SET_MESSAGE_AND_NOT_LOADING: 'SET_MESSAGE_AND_NOT_LOADING',
    SET_LOADING: 'set_loading',
    IS_LOADING: 'is_loading',
    IS_NOT_LOADING: 'is_not_loading',
    SET_ERROR: 'SET_ERROR'
}

function reducer(state, action) {

    switch (action.type) {
        case PAGE_STATE_ACTION.SET_MESSAGE_AND_NOT_LOADING:
            return {
                ...state,
                isLoading: false,
                hasMessage: true,
                status: action.payload.status,
                message: action.payload.message
            };
        case PAGE_STATE_ACTION.IS_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case PAGE_STATE_ACTION.SET_LOADING:
            return { ...state, isLoading: action.isLoading };
        case PAGE_STATE_ACTION.IS_NOT_LOADING:
            return { ...state, isLoading: false };
        case PAGE_STATE_ACTION.SET_ERROR:
            return {
                ...state,
                hasMessage: action.payload.hasMessage,
                status: action.payload.status,
                message: action.payload.message
            };
        default:
            return state;
    }
}

export const PageStateContext = createContext();

export const PageStateProvider = (props) => {
    const [pageState, pageSateDispatch] = useReducer(reducer, {
        isLoading: false,
        hasMessage: false,
        status: '',
        message: ''
    });

    return (
        <PageStateContext.Provider value={{ pageState, pageSateDispatch }}>
            {props.children}
        </PageStateContext.Provider>
    );
}