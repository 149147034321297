import React from 'react';
import Alert from './Alert';

const SuccessAlert = ({ message, isShow, onClose, subject }) => {

  return (
    <Alert
      type={'success'}
      message={message}
      isShow={isShow}
      onClose={onClose}
      subject={subject}
    />
  );
}

export default SuccessAlert;