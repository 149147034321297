export const ActionPortalTypes = {
    ADD_FILE_TO_REQUEST: 'ADD_FILE_TO_REQUEST',
    CHECK_REQUEST_STATUS: 'CHECK_REQUEST_STATUS',
    CANCEL_PERMIT: 'CANCEL_PERMIT',
    CHECK_PEMIT_VALIDITY: 'CHECK_PEMIT_VALIDITY'
  };

export const OtpStatus = {
  PERMIT_OR_REQUEST_DETAILS: 'PERMIT_OR_REQUEST_DETAILS',
  OTP_SENT_TO_MOBILE: 'OTP_SENT_TO_MOBILE',
  OTP_SUCCESS: 'OTP_SUCCESS'
}