import React, { useState } from 'react'

const RadioBtn = ({ value, id, name, onChange, onClick, onBlur, label, isChecked }) => {
    //console.log(isChecked)
    return (
        <>
            <input
                checked={isChecked}
                value={value}
                onBlur={onBlur}
                onChange={(e) => { }}
                id={id}
                name={name}
                className="btn-check"
                type='radio'
                autoComplete="off"
                onClick={(e) => onClick(e)}
            />
            <label 
                className="btn btn-outline-success" 
                htmlFor={id}
                >
                {label}
            </label>
        </>
    )
}

export default RadioBtn