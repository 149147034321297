import React from 'react';
import StudentPermitType from './StudentPermitType';
import { usePermitRequestContext } from '../../../../contexts/PermitRequestContext';
import GoldenAgePermit from './GoldenAgePermit';
import { useProjectConfigContext } from '../../../../contexts/ProjectConfigContext';
import DisabilityCardPermit from './DisabilityCardPermit';

function PermitRequestType() {

    const { values, setFieldValue } = usePermitRequestContext();
    const { projectConfigState } = useProjectConfigContext();

    const hasGoldenPermit = projectConfigState.tdsConfig.gilHaZahavOption;
    const hasStudentPermit = projectConfigState.tdsConfig.studentOption;
    const hasHandicappedOption = projectConfigState.tdsConfig.handicappedOption;

    return (
        <>
            {hasGoldenPermit ? <GoldenAgePermit permitType={values.permitType} handleClick={handleClick} /> : ''}
            {hasStudentPermit ? <StudentPermitType permitType={values.permitType} handleClick={handleClick} /> : ''}
            {hasHandicappedOption ? <DisabilityCardPermit permitType={values.permitType} handleClick={handleClick} /> : ''}
        </>
    );

    function handleClick(e) {
        setFieldValue('permitType', Number(e.currentTarget.value));
    }
}

export default PermitRequestType;