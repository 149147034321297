import * as yup from 'yup';
import { yupObjectCreditCardNotRequired, yupObjectCreditCardRequired } from './yupObjectCreditCard';
import { yupObjectCar } from './yupObjectCar';
import { yupObjectPersonDetails } from './yupObjectPersonDetails';
import { yupObjectContactDetails } from './yupObjectContactDetails';
import { yupObjectFiles } from './yupObjectFiles';
import { yupObjectAdditionalContactDetails, yupObjectAdditionalContactDetailsNotRequired, yupObjectAdditionalContactDetailsRequired } from './yupObjectAdditionalContactDetails';


export const requestSchema = yup.object().shape({
    carDetails: yup
        .object()
        .shape(yupObjectCar),
    personDetails: yup
        .object()
        .shape(yupObjectPersonDetails),
    contactDetails: yup
        .object()
        .shape(yupObjectContactDetails),
    additionalContactDetails: yup
        .object()
        .shape(yupObjectAdditionalContactDetailsRequired)
        .when(["hasAdditionalAddress"], ([hasAdditionalAddress], schema) => {

            return (hasAdditionalAddress === 0) ? schema.shape(yupObjectAdditionalContactDetailsNotRequired) : schema
        }),
    extraFiles: yup
        .object()
        .shape(yupObjectFiles),
    agreeTakanon: yup.string()
        .test(
            "agreeTakanon_",
            'חובה לאשר את התנאים וההגבלות',
            (value) => value == 1
        ),
    craditCard: yup
        .object()
        .shape(yupObjectCreditCardNotRequired)
        .when(["paymentAmount"], ([paymentAmount], schema) => {
            return (paymentAmount === 0) ? schema : schema.shape(yupObjectCreditCardRequired)
        }),
});
